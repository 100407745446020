const ACTIVE_HOST = process.env.REACT_APP_API_BASE_URL;
// const ACTIVE_HOST = "https://api.progresif.com";

const versions = {
  v1: ":4747/",
  v2: ":4747/",
};

const ServiceEnum = {
  oauth: "oauth/",
};

const Urls = {
  login: `${versions.v1}${ServiceEnum.oauth}token`,
  emailOtp: `${versions.v1}${ServiceEnum.oauth}care/send/emailotp`,
  registerProfile: `${versions.v1}${ServiceEnum.oauth}care/user/register`,
  linkMobileRegistration: `${versions.v1}${ServiceEnum.oauth}care/send/smsotp`,
  forgotPassword: `${versions.v1}${ServiceEnum.oauth}care/user/password/forgot`,
  linkMobile: `${versions.v1}${ServiceEnum.oauth}care/primary/add`,
  getDashboardData: `${versions.v2}${ServiceEnum.oauth}care/v2/user/dashboard/get`,
  getBillDetails: `${versions.v1}${ServiceEnum.oauth}care/user/billing/details`,
  getQueryMobile: `${versions.v1}${ServiceEnum.oauth}care/query/mobile`,
  getQueryFbb: `${versions.v1}${ServiceEnum.oauth}care/query/fbb`,
  getPlanDetails: `${versions.v1}${ServiceEnum.oauth}care/query/mobile`,
  getNonce: `${versions.v1}${ServiceEnum.oauth}care/nonce`,
  editProfile: `${versions.v1}${ServiceEnum.oauth}care/user/nickname/update`,
  uploadAvatar: `${versions.v1}${ServiceEnum.oauth}care/user/avatar/upload`,
  addonList: `${versions.v1}${ServiceEnum.oauth}care/addon/mobile/list`,
  getAddOn: `${versions.v1}${ServiceEnum.oauth}care/addon/mobile/list`,
  getFbbPlanDetails: `${versions.v1}${ServiceEnum.oauth}care/addon/fbb/list`,
  changePassword: `${versions.v1}${ServiceEnum.oauth}care/user/password/change`,
  getAddOnMobile: `${versions.v1}${ServiceEnum.oauth}care/addon/mobile/list`,
  getAddOnBroadband: `${versions.v1}${ServiceEnum.oauth}care/addon/fbb/list`,
  getAddonListplans: `${versions.v1}${ServiceEnum.oauth}care/query/listplans`,
  getPlanData: `${versions.v1}${ServiceEnum.oauth}care/prepaid/getplans`,
  getTopUp: `${versions.v1}${ServiceEnum.oauth}care/prepaid/topup/list`,
  saveCardDetails: `${versions.v1}${ServiceEnum.oauth}care/user/payment/save`,
  paymentByCard: `${versions.v1}${ServiceEnum.oauth}care/prepaid/topup/orderbycard`,
  paymentByToken: `${versions.v1}${ServiceEnum.oauth}care/prepaid/topup/orderbytoken`,
  getPaymentInfo: `${versions.v1}${ServiceEnum.oauth}care/user/payment/info`,
  deleteCard: `${versions.v1}${ServiceEnum.oauth}care/user/payment/delete`,
  prepaidSwitch: `${versions.v1}${ServiceEnum.oauth}care/prepaid/switch`,
  addOnOrderMobile: `${versions.v1}${ServiceEnum.oauth}care/v2/addon/mobile/order`,
  addOnOrderFbb: `${versions.v1}${ServiceEnum.oauth}care/v2/addon/fbb/order`,
  addOnOrderRecurringMobile: `${versions.v1}${ServiceEnum.oauth}care/recurringaddon/mobile/order`,
  getPaginationDataPost: `${versions.v2}${ServiceEnum.oauth}care/v2/user/dashboard/get`,
  getPaginationDataPre: `${versions.v2}${ServiceEnum.oauth}care/v2/user/dashboard/get`,
  sendCredit: `${versions.v1}${ServiceEnum.oauth}care/prepaid/sendcredit`,
  supplementaryMobileAdd: `${versions.v1}${ServiceEnum.oauth}care/supplementary/mobile/add`,
  supplementaryBroadbandAdd: `${versions.v1}${ServiceEnum.oauth}care/supplementary/fbb/add`,
  userLogout: `${versions.v1}${ServiceEnum.oauth}care/user/logout`,
  deleteAvatar: `${versions.v1}${ServiceEnum.oauth}care/user/avatar/delete`,
  billPaymentByCard: `${versions.v1}${ServiceEnum.oauth}care/user/billing/paybycard`,
  billPaymentByToken: `${versions.v1}${ServiceEnum.oauth}care/user/billing/paybytoken`,
  userPaymentRecurring: `${versions.v1}${ServiceEnum.oauth}care/user/payment/recurring`,
  purchases: `${versions.v1}${ServiceEnum.oauth}care/user/billing/purchases`,
  lifeStyleList: `${versions.v1}${ServiceEnum.oauth}care/dcb/closed/list`,
  lifeStyleClosedBuy: `${versions.v1}${ServiceEnum.oauth}care/dcb/closed/buy`,
  getPerksData: `${versions.v1}${ServiceEnum.oauth}care/init`,
  labelUpdate: `${versions.v1}${ServiceEnum.oauth}care/plan/label/update`,
  supplementaryRemove: `${versions.v1}${ServiceEnum.oauth}care/supplementary/remove`,
  supplementaryPromote: `${versions.v1}${ServiceEnum.oauth}care/supplementary/promote`,
  affiliateInfo: `${versions.v1}${ServiceEnum.oauth}care/v2/affiliate/get`,
  registerAffiliate: `${versions.v1}${ServiceEnum.oauth}care/affiliate/register`,
  affiliateTopUp: `${versions.v1}${ServiceEnum.oauth}care/affiliate/topup/list`,
  changeAffiliateCoupon: `${versions.v1}${ServiceEnum.oauth}care/affiliate/coupon`,
  sendCreditFavourite: `${versions.v1}${ServiceEnum.oauth}care/affiliate/favourite`,
  affiliateSendCredit: `${versions.v1}${ServiceEnum.oauth}care/affiliate/send`,
  getSettings: `${versions.v1}${ServiceEnum.oauth}care/user/settings/get`,
  setSettings: `${versions.v1}${ServiceEnum.oauth}care/user/settings/set`,
  getMobilePromo: `${versions.v2}${ServiceEnum.oauth}care/v2/addon/mobile/promo`,
  getQuickAddOn: `${versions.v2}${ServiceEnum.oauth}care/v2/addon/mobile/quickaddon`,
  getQuickAddOnFbb: `${versions.v2}${ServiceEnum.oauth}care/v2/addon/fbb/quickaddon`,
  getCategories: `${versions.v2}${ServiceEnum.oauth}care/v2/addon/mobile/list`,
  getCategoriesFbb: `${versions.v2}${ServiceEnum.oauth}care/v2/addon/fbb/list`,
};

export const urlFor = (service) => {
  return `${ACTIVE_HOST}${service}`;
};

export default Urls;
